@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131516;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-xs {
  @apply h-6 px-2 text-sh6 border transition ease-in-out duration-500 font-bold
}

.btn-sm {
  @apply h-8 px-4 text-sh4 border transition ease-in-out duration-500 font-bold
}

.btn-md {
  @apply h-9 px-8 text-sh4 border transition ease-in-out duration-500 font-bold
}

.btn-black {
  @apply bg-neutral-800 text-white hover:bg-white hover:text-neutral-800 hover:border-neutral-800 hover:fill-neutral-800
}

.btn-black-disabled {
  @apply bg-neutral-500 text-white hover:cursor-not-allowed transition-none duration-500
}

.btn-white {
  @apply bg-white border-neutral-800 text-neutral-800 hover:bg-neutral-800 hover:text-white
}

.btn-primary {
  @apply bg-primary-800 border-primary-900 text-white hover:bg-white hover:text-primary-800 hover:border-primary-800
}

.btn-error {
  @apply border-error-500 text-error-500 hover:bg-error-500 hover:text-white
}

.btn-success {
  @apply bg-success-700 text-white hover:bg-white hover:text-success-700 hover:border-success-700
}

.btn-warning {
  @apply bg-warning-500 text-white hover:bg-white hover:text-warning-500 hover:border-warning-500
}
